import React from 'react';
import "../styles/Intro.css";
import { motion } from "framer-motion";

const Intro = () => {
  const text = `Hello 👋, 
  I'm Scott Heng, 
  a Software Engineer 
  based in San Francisco, California`.split("\n");
  const lines = [
    <span key={1}>Hello <span class="wave">👋</span></span>,
    <span key={2}>I'm Scott Heng,</span>,
    <span key={3}>a software engineer</span>,
    <span key={4}>based in San Francisco, California</span>
  ];
  return (
    <div className="Intro">
        {lines.map((line, index) => (
            <motion.div
            key={index}
            initial={{ opacity: 0, x: -20, y: -10 }}
            animate={{ opacity: 1, x: 0,  y: 0 }}
            transition={{ duration: 0.5, delay: index * 2 }}
            >
            {line}
            </motion.div>
      ))}
    </div>
  );
}

export default Intro;