import '../styles/styles.css'
import React from "react";
import { projects } from "../data";

const Projects = ({ img, name, description, onClick }) => {
    const data = [
        // {key: "1", img: "../astronaut.jpeg", name: "Project 1", description: "Project 1 Description", url: ""},
        {link: "https://www.google.com/", image: "../astronaut1.png", subtitle: "", title: ""}
    ];

    return (
        <section id="projects" className="text-gray-400 bg-gray-900 body-font">
            <div className="header">Projects</div>
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-wrap -m-4">
                {projects.map((project) => (
                    <a
                    href={project.link}
                    key={project.image}
                    className="sm:w-1/2 w-100 p-4">
                    <div className="flex relative">
                        <img
                        alt="gallery"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        src={project.image}
                        />
                        <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                            <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                                {project.subtitle}
                            </h2>
                            <h1 className="title-font text-lg font-medium text-white mb-3">
                                {project.title}
                            </h1>
                            <p className="leading-relaxed">{project.description}</p>
                        </div>
                    </div>
                    </a>
                ))}
                </div>
            </div>
        </section>
        // <div className="Projects">
        //     <div className="mt-5 laptop:mt-10 grid grid-cols-1 tablet:grid-cols-2 gap-4">
        //         {data.map((project) => (
        //         <ProjectCard
        //             key={project.id}
        //             img={project.imageSrc}
        //             name={project.title}
        //             description={project.description}
        //             onClick={() => window.open(project.url)}
        //         />
        //         ))}
        //     </div>
        // </div>
        );
}

export default Projects